import axios from 'axios'
import { admingetRequest, admingetSuccess ,adminfailed,clearError,loadadminSuccess,loadadminRequest ,logoutadminRequest,logoutadminSuccess} from '../slice/adminslice'

export const getAdmin = (adminData) => async(dispatch) =>{
    try{
        dispatch(admingetRequest())
        const {data} = await axios.post('/api/v1/adminlogin',adminData,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        console.log(data)
        dispatch(admingetSuccess(data))
    }
    catch(err){
        dispatch(adminfailed(err.response.data.message))
    }
    
}
export const errorclr = dispatch =>{
    dispatch(clearError())
}

export const loadAdmin  = async(dispatch)=>{
    try{
        dispatch(loadadminRequest())
        const {data} = await axios.get('/api/v1/loadadmin')
        console.log(ImageData)
        dispatch(loadadminSuccess(data))
    }
    catch(err){
        
    }
}

export const logoutAdmin  = async(dispatch)=>{
    try{
        dispatch(logoutadminRequest())
        await axios.get('/api/v1/logout')
        dispatch(logoutadminSuccess())
    }
    catch(err){
    
    }
}