import React from 'react'
import {Container,Grid,Box,Paper} from '@mui/material'
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StorageIcon from '@mui/icons-material/Storage';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SmsIcon from '@mui/icons-material/Sms';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import image1 from '../../asset/image/slide1.jpg'
import image2 from '../../asset/image/slide7.jpg'
import image3 from '../../asset/image/slide3.jpg'
import image4 from '../../asset/image/slide4.jpg'
import image5 from '../../asset/image/slide5.jpg'
import image6 from '../../asset/image/slide6.jpg'
import ReactCardCarousel from 'react-card-carousel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


const BannerSecound = () => {

    const sliderClick = (slider)=>{
        alert("hello world");
      }
    const slides = [
        { image: image1,title:"This is a title",description:"This is a description",clickEvent: () => sliderClick(image1) },
        { image: image2,title:"This is a title",description:"This is a description",clickEvent: () => sliderClick(image2) },
        { image: image3,title:"This is a title",description:"This is a description",clickEvent: () => sliderClick(image3) },
        { image: image4,title:"This is a title",description:"This is a description",clickEvent: () => sliderClick(image4) },
        { image: image5,title:"This is a title",description:"This is a description",clickEvent: () => sliderClick(image5) },
        { image: image6,title:"This is a title",description:"This is a description",clickEvent: () => sliderClick(image6) }
      ];
      const CARD_STYLE ={
          height: '180px',
          width: '200px',
          textAlign: 'center',
          background: '#52C0F5',
          color: '#FFF',
          fontSize: '12px',
          textTransform: 'uppercase',
          borderRadius: '10px',
      }
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
  return (
    <div style={{marginTop:'30px'}}>
        <Container maxWidth='md'>
            <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} sm={3}>
                <Item><CloudUploadIcon sx={{fontSize:'60px'}}/><br />ஆன்லைனில் மொய் கணக்குகள்</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><StorageIcon sx={{fontSize:'60px'}}/><br /> மொய்களை சேமிக்கும் வசதி</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><PersonSearchIcon sx={{fontSize:'60px'}}/><br /> தனிநபரின் மொய் விபரங்கள்</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><SmsIcon sx={{fontSize:'60px'}}/><br />விசேஷங்களின் SMS நினைவூட்டல்</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><ReceiptIcon sx={{fontSize:'60px'}}/><br />மொய் செய்ததற்கான உடனடி இரசீது</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><StackedBarChartIcon sx={{fontSize:'60px'}}/><br />மொய் செய்த / வந்த தொகை ஆராய்வு</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><CalendarMonthIcon sx={{fontSize:'60px'}}/><br />விசேஷங்களின் விபரங்கள் / நிலை</Item>
                </Grid>
                <Grid item xs={6} sm={3}>
                <Item><MobileFriendlyIcon sx={{fontSize:'60px'}}/><br />கைபேசியில் மொய் விபரங்கள்</Item>
                </Grid>
               
            </Grid>
            </Box>
        </Container>
        <h3 style={{marginTop:'30px',textAlign:'center'}}>
            விசேஷங்களின் கண்ணோட்டம்
        </h3>
        <div className='cardSlider' style={{margin:'120px auto',position:'relative',display: "flex",flex: 1,justifyContent: "center",alignItems: "middle"}}>
            
            <ReactCardCarousel autoplay={ true } autoplay_speed={ 2500 }>
                <div style={ CARD_STYLE }>
                <Card sx={{ maxWidth: 400 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        image={image1}
                        alt="green iguana"
                        sx={{
                            objectFit: 'cover',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                        }}
                        />
                    </CardActionArea>
                    </Card>
                </div>
                <div style={ CARD_STYLE }>
                <Card sx={{ maxWidth: 400 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        image={image2}
                        alt="green iguana"
                        sx={{
                            objectFit: 'cover',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                        }}
                        />
                    </CardActionArea>
                    </Card>
                </div>
                <div style={ CARD_STYLE }>
                <Card sx={{ maxWidth: 400 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        image={image3}
                        alt="green iguana"
                        sx={{
                            objectFit: 'cover',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                        }}
                        />
                    </CardActionArea>
                    </Card>
                </div>
            </ReactCardCarousel>
        </div>
    </div>
  )
}

export default BannerSecound