import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { pdfgen , getSingleuser} from '../../../../redux/actions/userAction'
import { useDispatch, useSelector } from 'react-redux'
import './pdfgen.css'



const Subpdfgen = () => {
  const dispatch = useDispatch()
  const {area} = useSelector(state=>state.userstate)
  const {siguser} = useSelector(state=>state.userstate)
  const [pdfdata,setpdfdata] = useState([])
  const [oldindex,setoldindex] = useState([])
  const {state} = useLocation()
  const [totalamount,setTotalamount] = useState()
  const{id} = state
  const [inoperator,setInoprator] = useState([])
  const sigsymbols = ["♥","♦","♣","♠","◘","○", "◙","♪", "♫","☼","►","◄","↕","‼","¶","§","↨","↑","↓","→","←","∟","↔","▲","▼"]
  useEffect(()=>{
    dispatch(pdfgen(id))
    dispatch(getSingleuser(id))
  },[dispatch,id])

  useEffect(() => {
    if(pdfdata.length == 0)
    {
      if (area && area.area) {
        area.area.map(a => {
          if(siguser && siguser.getCus){
            siguser.getCus.moiList.map((list,index)=>{
              if(a.area == list.area.trim()){
                setpdfdata(prepdfdata=>[...prepdfdata,list])
                setoldindex(preoldindex=>[...preoldindex,index])
              }
            })
          }
        });
      }
      
    }
    
  }, [area,siguser]);


  useEffect(()=>{
    let totalamount = 0;
    if(siguser){
      const operator = new Set()
      siguser.getCus.moiList.map((list,index)=>{
        totalamount = totalamount + list.amount
        operator.add(list.operator)
      })
      setTotalamount(totalamount)
      const operatorArray = Array.from(operator);
      setInoprator(operatorArray)
    }
  },[siguser])


  const areaTotals = {};

  return (
    <div className="pdfgen">
      <div id="pdf-content">
          <div className="pdfIndexCon">
          <div className="pdfIndex">
            <h1>Index page</h1>
            <table className='mytableclass'>
              <thead>
                <tr>
                  <th>வ.எண்</th>
                  <th>ஊர் பெயர்</th>
                  <th>பக்கம் எண்</th>
                </tr>
              </thead>
              <tbody>
                  {area && area.area.map((a, index)=>
                      <tr>
                      <td className='indexnum'>{index + 1}</td>
                      <td className='indexooru'>{a.area}</td>
                      <td className='indexpage'>0</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          </div>
          
          {/* body pdf content */}
        <div className="pdfbody">
        <table>
              <thead className='headercount'><tr><th>பக்கம் </th></tr></thead>
              <tbody>
              <table>
        <thead>
          <tr className='maintabletr'>
            <th >வ.எண்</th>
            <th>பெயர் மற்றும் ஊர்</th>
            <th>செய்த மொய்</th>
            <th>வந்த மொய்</th>
            <th>வந்த மொய்</th>
            <th>வந்த மொய்</th>
          </tr>
        </thead>
        <tbody className='mainbody'>
  {area && area.area.map((a) => {
    // Initialize total sum for each area
    areaTotals[a.area] = 0;

    return (
      <React.Fragment key={a.area}>
        <tr>
          <th align='center' colSpan={6}>{a.area}</th>
        </tr>
        {pdfdata && pdfdata.map((list, index) => {
          if (a.area === list.area.trim()) {
            areaTotals[a.area] += parseFloat(list.amount);
            return (
              <>
              <tr key={index}>
                <td align='center'>{index + 1}</td>
                {/* <td align='center'>{oldindex[index] + 1}</td> */}
                <td>{list.name}{list.business !== '' && `(${list.business})`} <br /> {list.area} </td>
                <td></td>
                <td align='center'>{list.amount}</td>
                <td></td>
                <td className='symbol'>
                {inoperator && inoperator.map((op,index)=>{
                  if(list.operator == op){
                   return <p>{sigsymbols[index]}</p>
                  }
                })}
                </td>
              </tr>
              <tr>
                <th className='emptyspace' align='center' colSpan={6} ></th>
              </tr>
              </>
            );
          }
          return null; // Return null for entries that don't match the current area
        })}
      </React.Fragment>
    );
  })}
  
</tbody>
<tfoot>
  <tr>
      <td colSpan={3}><h3>பக்கத்தின் மொத்ததொகை</h3></td>
      <td></td>
      <td align='center'><h3>0</h3></td>
      <td></td>
      <td></td>
    </tr>
</tfoot>
  <tr>
      <td colSpan={3}><h3>மொத்ததொகை</h3></td>
      <td></td>
      <td align='center'><h1>{totalamount}</h1></td>
      <td></td>
      <td></td>
    </tr>
      </table>
                
              </tbody>
            </table>
          </div>
          <div className="operatorsy">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Symbol</th>
              </tr>
            </thead>
            <tbody>
              {inoperator && inoperator.map((op, index) => (
                <tr key={index}>
                  <td align='center' >{op}</td>
                  <td align='center' >{sigsymbols[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
      </div>
    </div>
  )
}

export default Subpdfgen