import React, { useState ,useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { login ,errorclr} from '../../redux/actions/userAction'
import Loading from '../mainpage/asset/minicomponent/loading/Loading'
import Navbar from './asset/Navbar'
import ImageSlider from './asset/ImageSlider'
import TextField from '@mui/material/TextField';
import TelegramIcon from '@mui/icons-material/Telegram';
import BannerFrist from './asset/BannerFrist'
import BannerSecound from './asset/BannerSecound'
import Footer from './asset/Footer'

const Login = () => {
    const navigator = useNavigate()
    const {loading , user,error} = useSelector((state)=>state.userstate)
    useEffect(()=>{
        if(user){
            navigator('/view')
        }
        if(error){
            toast(error , {
                position:toast.POSITION.BOTTOM_CENTER,
                type:'error',
                onOpen:()=> {dispatch(errorclr)}
            })
            return
        }
    },[error,user])

    const dispatch = useDispatch()
    const [userData,setuserData] = useState({
        email:'',
        password:''
    })

    const onchange = (e) =>{
        setuserData({...userData,[e.target.name]:e.target.value})
    }

    const onSubmitHandler = (e) =>{
        e.preventDefault()
        console.log(userData.email)
        const formdata = new FormData()
        formdata.append("email",userData.email)
        formdata.append("password",userData.password)
        dispatch(login(formdata))
    }

    

  return (
    <>
        {loading ? <Loading/> :
    <div className="view">
        <Navbar/>
        <div className="BannerFrist">
            <div className="LoginCon">
                <div className="Log">
                    <div className="top">
                        <h2>உள்நுழை <TelegramIcon/></h2>
                        <h4>தகவல்களை தேர்வு செய்</h4>
                    </div>
                    <div className="buttom">
                        <form onSubmit={onSubmitHandler}>
                            <div className="Getinput">
                                <div className="username">
                                    <TextField id="outlined-basic" name='email' onChange={onchange} className='email'  label="Email" variant="outlined" inputProps={{style: { height: '20px', padding: '10px' }}} fullWidth />
                                </div>
                                <div className="password">
                                    <TextField id="outlined-basic" type='password' name='password' onChange={onchange} className='password'  label="Password" variant="outlined"  inputProps={{style: { height: '20px', padding: '10px' }}} fullWidth />
                                </div>
                                <button type='submit' style={{color:'#fff',fontSize:'1rem',background:'#20c997',marginTop:'10px',padding:'10px 20px',borderRadius:'10px',border:'none'}}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="slider">
                <ImageSlider/>
            </div>
        </div>
        <BannerFrist/>
        <BannerSecound/>
        <Footer/>
    </div>

}
</>
  )
}

export default Login