import React from 'react'
import './loading.css'
const Loading = () => {
  return (
    <div>
        <section id="global">

<div id="top" class="mask">
  <div class="plane"></div>
</div>
<div id="middle" class="mask">
  <div class="plane"></div>
</div>

<div id="bottom" class="mask">
  <div class="plane"></div>
</div>

<p className='loadingp'><i>LOADING...</i></p>

</section>
    </div>
    
  )
}

export default Loading