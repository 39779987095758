import React, { useEffect, useState } from 'react'

function Countamount (props) {
  const {siguser} = props
  const [total,setTotal] =useState()
  useEffect(()=>{
      let subtotal=0
      siguser.cus.moiList.map(list=>{
          subtotal = subtotal + list.amount
      })
      setTotal(subtotal)
  },[siguser])
  
return (
  <div>
      <h6>Totalamount</h6>
      <h3>{total}</h3>
  </div>
)
}

export default Countamount