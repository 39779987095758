import axios from 'axios';
import { userRequest ,userLogin ,delCus,userLoginSuccess,userSuccess,userLoginFailed,clearError,userSingleSuccess,userSingleRequest, createuserReq, createuserSuccess,updatemoiReq,updatemoiSuccess,indelReq,indelSuccess,inupdateReq,inupdateSuccess,adupdatemoiReq,adupdatemoiSuccess,loaduserSuccess,loaduserRequest,logoutuserRequest,logoutuserSuccess,pdfgenRequest,pdfgenSuccess, clrUser,changeperReq,changeperSuccess} from "../slice/userslice"

export const getUser = async (dispatch)=>{
    try{
        dispatch(userRequest())
        const {data} = await axios.get('/api/v1/getCus')
        dispatch(userSuccess(data))
    }
    catch(err){

    }
    
}

export const login = (userdata) => async (dispatch)=>{
    try{
        dispatch(userLogin())
        const {data} = await axios.post('/api/v1/login',userdata, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          console.log(data)
        dispatch(userLoginSuccess(data))
    }
    catch(err){
        dispatch(userLoginFailed(err.response.data.message))
    }
    
}

export const errorclr = dispatch =>{
    dispatch(clearError())
}
export const clearUser = dispatch =>{
    dispatch(clrUser())
}

export const getSingleuser= (id) =>async (dispatch)=>{
    try{
        dispatch(userSingleRequest())
        const {data} = await axios.get(`/api/v1/getsinglecus/${id}`)
        dispatch(userSingleSuccess(data))
    }
    catch(err){

    }
    
}

export const createuser = (userdata) => async(dispatch) =>{
    try{
        console.log(userdata)
        dispatch(createuserReq())
        const {data} = await axios.post('/api/v1/register',userdata, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        dispatch(createuserSuccess(data))
    }
    catch(err){
        console.log(err)
    }
}


export const updatemoi = (id,userdata) =>async (dispatch) =>{
    try{
        dispatch(updatemoiReq())
        const {data} = await axios.put(`/api/v1/updatecus/${id}`,userdata,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        dispatch(updatemoiSuccess(data))
    }
    catch(err){

    }
}

export const adupdatemoi = (id,userdata) =>async (dispatch) =>{
    try{
        console.log(userdata)
        dispatch(adupdatemoiReq())
        const {data} = await axios.put(`/api/v1/adupdatecus/${id}`,userdata,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        console.log(data)
        dispatch(adupdatemoiSuccess(data))
    }
    catch(err){

    }
}
export const changeperAction = (id,userdata) =>async (dispatch) =>{
    try{
        console.log(id)
        dispatch(changeperReq())
        const {data} = await axios.put(`/api/v1/changeper/${id}`,userdata,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        console.log(data)
        dispatch(changeperSuccess(data))
    }
    catch(err){

    }
}
export const deleteCus = (id) =>async (dispatch) =>{
    try{
        dispatch(delCus)
        const {del} = await axios.delete('/api/v1/delCus/'+id)
        const {data} = await axios.get('/api/v1/getCus')
        dispatch(userSuccess(data))
    }
    catch(e){

    }
}


export const indelcus = (id,userdata) =>async (dispatch) =>{
    try{
        console.log(id)
        console.log(userdata)
        dispatch(indelReq())
        const {data} = await axios.put(`/api/v1/indelcus/${id}`,userdata,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        dispatch(indelSuccess(data))
    }
    catch(err){

    }
}

export const ineditcus = (id,userdata) =>async (dispatch) =>{
    try{
        dispatch(inupdateReq())
        const {data} = await axios.put(`/api/v1/inupdatecus/${id}`,userdata,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        dispatch(inupdateSuccess(data))
    }
    catch(err){

    }
}

export const loaduser  = async(dispatch)=>{
    try{
        dispatch(loaduserRequest())
        const {data} = await axios.get('/api/v1/loaduser')
        dispatch(loaduserSuccess(data))
    }
    catch(err){

    }
}

export const logoutuser  = async(dispatch)=>{
    try{
        dispatch(logoutuserRequest())
        await axios.get('/api/v1/logout')
        dispatch(logoutuserSuccess())
    }
    catch(err){

    }
}

export const pdfgen= (id) =>async (dispatch)=>{
    try{
        dispatch(pdfgenRequest())
        const {data} = await axios.get(`/api/v1/pdfgen/${id}`)
        console.log(data)
        dispatch(pdfgenSuccess(data))
    }
    catch(err){

    }
    
}