import React, {useState, useEffect ,useRef} from 'react'
import Main from './component/mainpage/Main'
import { BrowserRouter ,Routes ,Route} from 'react-router-dom'
import Login from './component/login/Login'
import Home from './component/mainpage/asset/Home'
import Edit from './component/mainpage/asset/Edit'
import { Register } from './component/Register'
import {Provider, useDispatch} from 'react-redux'
import store from './redux/store'
import Admin from './component/admin/Admin'
import View from './component/customerview/CustomerView'
import Authroute from './component/asset/authroute/Authroute'
import Cusauthroute from './component/asset/authroute/Cusauthroute'
import Loginauth from './component/asset/authroute/Loginauth'
import { loaduser } from './redux/actions/userAction'
import { loadAdmin } from './redux/actions/adminAction'
import Printing from './component/mainpage/asset/Printing'
import Pdfgen  from './component/mainpage/asset/pdfgenerate/Pdfgen'
import Pdfsubgen  from './component/mainpage/asset/pdfgenerate/Pdfsubgen'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Userdata from './component/userdata/Userdata'
const App = () => {


  const url =  window.location.pathname
  console.log(url)
  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await fetch('/public/inputtool/tr');
        const data = await response.json();
        console.log(data); // Do something with the data
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchData();
    if(url == '/login' || url == '/login/' ||url == '/view/' || url == '/view'){
      store.dispatch(loaduser)
    }else{
      store.dispatch(loadAdmin)
    } 
  },[])

  return (

    <Provider store={store}>
      <ToastContainer />
      <ToastContainer />
        <BrowserRouter>
        <Routes>
          
          <Route index element={<Login/>}/>
          <Route path='view' element={<View/>}/>
          <Route path='admin' element={<Admin/>}/>
          <Route path='register' element={<Register/>}/>
          <Route path='printing' element={<Printing/>}/>
          <Route path='pdf_generator' element={<Pdfgen/>}/>
          <Route path='pdf_generatorsub' element={<Pdfsubgen/>}/>
          <Route path='main' element={<Authroute><Main/></Authroute>}>
            <Route index element={<Authroute><Home/></Authroute>}/>
            <Route path=':id' element={<Authroute><Home/></Authroute>}/>
            <Route path='edit/:id' element={<Authroute><Edit /></Authroute>}/>
            <Route path='userdata' element={<Authroute><Userdata/></Authroute>}/>
            <Route path='*' element={<Authroute><Home/></Authroute>}/>
          </Route>
          <Route path='*' element={<Login/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App