import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <div style={{background:'#4c566a'}}>
        <div className="footerleft" style={{width:'80%',padding:'15px',color:'#fff',margin:'auto',display:'flex',justifyContent:'space-between'}}>
            <h3>
                சுருதி மொய்நோட் 
            </h3>
            <div className="logo">
                <InstagramIcon/>
                <FacebookIcon/>
                <TwitterIcon/>
            </div>
        </div>
    </div>
  )
}

export default Footer