import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';

const drawerWidth = 300;

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });

  function appBarLabel(label) {
    return (
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <MenuIcon /> 
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        சுருதி மொய் நோட் 
        </Typography>
      </Toolbar>
    );
  }

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      சுருதி மொய் நோட் 
      </Typography>
      <Divider />
        <List>
            <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary='முகப்பு' />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary='சுருதி மொய்நோட்  ஆப்' />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary='எங்களை தொடர்புகொள்ள' />
                </ListItemButton>
            </ListItem>
        </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>
      <AppBar component="nav" position="static" color="primary" enableColorOnDark sx={{boxShadow:'none',padding:'0',maxHeight:'auto'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon /> <h6 style={{color:'#4dabf7'}}>சுருதி மொய்நோட் </h6>
          </IconButton>
          <Typography
            variant="h5 "
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' },fontWeight:'520',color:'#4dabf7' }}
          >
            சுருதி மொய்நோட்  
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Stack spacing={2} direction="row"> 
                <Button variant="contained" sx={{ color: '#1864ab',fontWeight:'800',fontSize:'15px',boxShadow:'none' , background:'#d0ebff', '&:hover':{background:'#4dabf7',color:'#fff',boxShadow:'none'} }}>
                    <CottageOutlinedIcon sx={{marginRight:'2px'}}/>முகப்பு
                </Button>
                <Button variant="contained" sx={{ color: '#2e3440',fontSize:'15px',boxShadow:'none' , '&:hover':{background:'#d0ebff',color:'#1864ab',boxShadow:'none'} }}>
                    <AppShortcutOutlinedIcon sx={{marginRight:'2px'}} />சுருதி மொய்நோட்  ஆப்
                </Button>
                <Button variant="contained" sx={{ color: '#2e3440',fontSize:'15px',boxShadow:'none' , '&:hover':{background:'#d0ebff',color:'#1864ab',boxShadow:'none'} }}>
                    <PermContactCalendarOutlinedIcon/> எங்களை தொடர்புகொள்ள
                </Button>
              </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
