import React, { useEffect,useState } from 'react'

const Singleoperator = (props) => {
    const {siguser} = props
    const {name} = props
    const[Oamount,setOamount] = useState()
    const[Operson,setOperson] = useState()
    useEffect(()=>{
        let amount=0;
        let person=0;
        siguser.getCus.moiList.map(list=>{
            if(name === list.operator){
                person = person + 1
                amount = amount + list.amount
            }
            setOamount(amount)
            setOperson(person)
        })
    },[siguser])
  return (
    <div>
        <h3>Name   : {name}</h3>
        <h4>Person : {Operson}</h4>
        <h2>Amount  : {Oamount}</h2>
    </div>
  )
}

export default Singleoperator