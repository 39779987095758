import {requestArea,successRequestArea} from '../slice/areaslice'
import axios from 'axios'

export const getArea = async(dispatch)=>{
    try{
        dispatch(requestArea())
        const {data} = await axios.get('/api/v1/area')
        dispatch(successRequestArea(data))
    }
    catch(e){

    }
}