import React, { useEffect, useState ,useRef, Fragment} from 'react';
import './edit.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleuser, indelcus, ineditcus, updatemoi, pdfgen,errorclr, changeperAction} from '../../../redux/actions/userAction';
import { Link, useParams } from 'react-router-dom';
import { ReactTransliterate } from 'react-transliterate';
import "react-transliterate/dist/index.css";
import { ToWords } from 'to-words'
import { getArea } from '../../../redux/actions/areaAction';
import { Totalamount } from './minicomponent/Totalamount';
import Cookies from 'universal-cookie'
import Loading from './minicomponent/loading/Loading'
import { toast } from 'react-toastify'
import Singleoperator from './minicomponent/Singleoperator'
import Adminoperator from './minicomponent/Adminoperator';
import ReplayIcon from '@mui/icons-material/Replay';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { getTotalUser } from '../../../redux/actions/totalUserAction';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
const Edit = () => {
  const [userEditData, setUserEditData] = useState({
    name: "",
    business: "",
    area: ""
  });
  const [demonAmount,serDemonAmount] = useState({
    twoThousand:0,
    oneThousand:0,
    fiveHundrend:0,
    twoHundrend:0,
    oneHundrend:0,
    fifty:0,
    twenty:0,
    ten:0
  })
  const [amount, setAmount] = useState(0);
  const [amountlet, setAmountlet] = useState('');
  const [search, setSearch] = useState('');
  const [inedit, setInedit] = useState(false);
  const [cus_id, setCus_id] = useState('');
  const dispatch = useDispatch();
  const [permission,setPermission] = useState(false)
  const { id } = useParams();
  const { loading, siguser,stopmoi } = useSelector(state => state.userstate);
  const  {place} = useSelector(state => state.areastate);
  const {  area } = useSelector(state => state.userstate);
  const { admin } = useSelector(state => state.adminstate);
  const {totaluser} = useSelector(state=>state.totalUserstate);
  const [inumber,setInumber] = useState('') 
  let cookies = new Cookies()
  useEffect(() => {
    
    if(!cookies.get('operator')){
      let operator = window.prompt('Enter you name')
      cookies.set('operator',operator,{path:'/'})
    }
    
    const fetchdata = async()=>{
      await dispatch(getSingleuser(id));
      dispatch(getArea)
      dispatch(pdfgen(id))
  }
  fetchdata()
  }, [dispatch]);
  

 useEffect(()=>{
  const toWords = new ToWords();
  
  const word = toWords.convert(Number(amount));
  // console.log(word)
  // const fetchTranslation = async () => {
  //   const sourceLang = 'en';
  //   const targetLang = 'ta';
  //   const apiUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${sourceLang}&tl=${targetLang}&dt=t&q=${encodeURIComponent(word)}`;
  
  //   try {
  //     const response = await fetch(apiUrl);
  //     const data = await response.json();
  
  //     if (data && data[0] && data[0][0] && data[0][0][0]) {
  //       const translatedText = data[0][0][0];
  //       setAmountlet( translatedText)
  //     } else {
  //       throw new Error('Translation not available.');
  //     }
  //   } catch (error) {
  //     console.error('Error occurred while translating:', error);
  //     throw error;
  //   }
  // };
  // const tamilword =  fetchTranslation()
  setAmountlet(word)
 },[amount])

  const numToWord = (event) => {
    const inputNumber = event.target.value;
    setAmount(inputNumber);
  };
  

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (userEditData.name && userEditData.area && amount) {
      const formData = new FormData();
      formData.append("name", userEditData.name);
      formData.append("business", userEditData.business);
      formData.append("area", userEditData.area);
      formData.append("amount", amount);
      formData.append("amountlet", amountlet);
      formData.append("number", inumber);
      formData.append("demoAmount.twoThousand", demonAmount.twoThousand);
      formData.append("demoAmount.oneThousand", demonAmount.oneThousand);
      formData.append("demoAmount.fiveHundrend", demonAmount.fiveHundrend);
      formData.append("demoAmount.twoHundrend", demonAmount.twoHundrend);
      formData.append("demoAmount.oneHundrend", demonAmount.oneHundrend);
      formData.append("demoAmount.fifty", demonAmount.fifty);
      formData.append("demoAmount.twenty", demonAmount.twenty);
      formData.append("demoAmount.ten", demonAmount.ten);
      formData.append("operator", cookies.get('operator'));
      if (inedit) {
        formData.append("id", cus_id);
        console.log(formData)
        dispatch(ineditcus(id, formData));
        setInedit(false);
      } else {
        dispatch(updatemoi(id, formData));
      }

      setUserEditData({
        name: "",
        business: "",
        area: ""
      });
      serDemonAmount({
        twoThousand:0,
        oneThousand:0,
        fiveHundrend:0,
        twoHundrend:0,
        oneHundrend:0,
        fifty:0,
        twenty:0,
        ten:0
      })
      setInumber(null)
      setAmount(0);
      setInumber('')
      setAmountlet("");
      dispatch(pdfgen(id))
    }
    else{
      toast("completed required Tag ", {
          position:toast.POSITION.BOTTOM_CENTER,
          type:'error',
          onOpen:()=> {dispatch(errorclr)}
      })
      return
  }
    
  };

  const onDelete = (cus_id) => {
    if (window.confirm("Want to Delete")) {
      const formData = new FormData();
      setUserEditData({
        name: "",
        business: "",
        area: ""
      });
      formData.append("id", cus_id);
      dispatch(indelcus(id, formData));
    }
  };

  const onEdit = (cus_id, name, business, area, amount, amountlet,number) => {
    setCus_id(cus_id);
    setUserEditData({
      name: name,
      business: business,
      area: area
    });
    console.log(number)
    if(number == 0 || number == '' || number == null){
      setInumber('')
    }
    else{
      setInumber(number)
    }
    setAmount(amount);
    setAmountlet(amountlet);
    setInedit(true);
  };

  const addiruppu = (e) =>{
    if (e.ctrlKey && e.key === 'y') {
      setUserEditData(prevData => ({ ...prevData, area: userEditData.area + '(இ)' }))
    }
  }

  useEffect(() => {
    let totalAmount = 0;
  
    totalAmount +=
      10 * demonAmount.ten +
      20 * demonAmount.twenty +
      50 * demonAmount.fifty +
      100 * demonAmount.oneHundrend +
      200 * demonAmount.twoHundrend +
      500 * demonAmount.fiveHundrend +
      1000 * demonAmount.oneThousand +
      2000 * demonAmount.twoThousand;
    setAmount(totalAmount);
  }, [demonAmount]);

  const setZero = (e) =>{
    e.preventDefault()
    serDemonAmount({
      twoThousand:0,
      oneThousand:0,
      fiveHundrend:0,
      twoHundrend:0,
      oneHundrend:0,
      fifty:0,
      twenty:0,
      ten:0
    })
  }

  const getNumberData = (e) =>{
    e.preventDefault();
    console.log(inumber.length)
    if(inumber.length == 10)
    {
      dispatch(getTotalUser(inumber)) 
    }
    else{
      toast("check number", {
        position:toast.POSITION.BOTTOM_CENTER,
        type:'error',
        onOpen:()=> {dispatch(errorclr)}
    })
    setUserEditData(prev => ({
      ...prev,
      name:'',
      business:'',
      area:'',
    }));
    }
  }
  const offpermission = (e) =>{
    e.preventDefault()
    const formData = new FormData();
    formData.append('permission',true)
    console.log(id)
    dispatch(changeperAction(id,formData))
  }
  const onpermission = (e) =>{
    e.preventDefault()
    let pass = prompt('Enter password')
    console.log(admin.admin.subpassword)
    if(pass == admin.admin.subpassword){
    const formData = new FormData();
    formData.append('permission',false)
    console.log(id)
    dispatch(changeperAction(id,formData))
    }
    else{
      toast("Content your Admin ", {
        position:toast.POSITION.BOTTOM_CENTER,
        type:'error',
        onOpen:()=> {dispatch(errorclr)}
    })}
  }
  useEffect(()=>{
    if (totaluser && totaluser.data && inumber) {
      setUserEditData(prev => ({
        ...prev,
        name: totaluser.data.name || '',
        business: totaluser.data.business || '',
        area: totaluser.data.area || '',
      }));
    }
  },[totaluser])

  useEffect(()=>{
    console.log('siguser:', siguser);
    if(siguser){
      console.log(siguser.getCus.permission)
      if(siguser.getCus.permission == 'false'){

        setPermission(false)
      }
      else{
        setPermission(true)
      }
    }
  },[siguser])
  return (
    <Fragment>
    {
      // loading ? <Loading/>:
    <div className="edit">
      <div className="editCon">
        <div className="left">
          <div className="top">
            <h3>Moi Register</h3>
            <form onSubmit={onSubmitHandler}>
              <div className="input">
                <div className="inputIn">
                  <div className="input_left">
                    {siguser &&
                      <div className="cus">
                        <h2>{siguser.getCus.username}</h2>
                        <p>{siguser.getCus.address}</p>
                      </div>
                    }
                    <div className='detailp'>
                      <div className="name">
                        <label htmlFor="name">Name</label>
                        <ReactTransliterate
                          value={userEditData.name}
                          onChangeText={(text) => setUserEditData(prevData => ({ ...prevData, name: text }))}
                          lang='ta'
                          name='name'
                          placeholder='Enter Name'
                          autoComplete='off'
                          
                        />
                      </div>
                      <div className="business">
                        <label htmlFor="business">Business</label>
                        <ReactTransliterate
                          value={userEditData.business}
                          onChangeText={(text) => setUserEditData(prevData => ({ ...prevData, business: text }))}
                          lang='ta'
                          name='business'
                          placeholder='Enter Business'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='demonAmount'>
                      <div><label htmlFor="amount">amount</label></div>
                      <div className="demoCon">
                      <div className="domInLeft">
                      <div className="demoin_size">
                      <span>10</span><input type="number" className='demon' id='ten' onChange={(e)=>serDemonAmount({...demonAmount,ten:e.target.value})}  placeholder='1' name='ten' value={demonAmount.ten}/>
                      </div>
                      <div className="demoin_size">
                      <span>20</span><input type="number" className='demon' id='twenty' onChange={(e)=>serDemonAmount({...demonAmount,twenty:e.target.value})}  placeholder='20' name='twenty' value={demonAmount.twenty}/>
                      </div>
                      <div className="demoin_size">
                      <span>50</span><input type="number" className='demon' id='fifty' onChange={(e)=>serDemonAmount({...demonAmount,fifty:e.target.value})}  placeholder='50' name='fifty' value={demonAmount.fifty}/>
                      </div>
                      <div className="demoin_size">
                      <span>100</span><input type="number" className='demon' id='hundrend' onChange={(e)=>serDemonAmount({...demonAmount,oneHundrend:e.target.value})}  placeholder='100' name='oneHundrend' value={demonAmount.oneHundrend}/>
                      </div>
                      </div>
                      <div className="domInRight">
                      <div className="demoin_size">
                      <span>200</span><input type="number" className='demon' id='twohundrend' onChange={(e)=>serDemonAmount({...demonAmount,twoHundrend:e.target.value})}  placeholder='200' name='twoHundrend' value={demonAmount.twoHundrend}/>
                      </div>
                      <div className="demoin_size">
                      <span>500</span><input type="number" className='demon'  id='fivehundrend' onChange={(e)=>serDemonAmount({...demonAmount,fiveHundrend:e.target.value})}  placeholder='500' name='fiveHundrend' value={demonAmount.fiveHundrend}/>
                      </div>
                      <div className="demoin_size">
                      <span>1000</span><input type="number" className='demon' id='thousand' onChange={(e)=>serDemonAmount({...demonAmount,oneThousand:e.target.value})}  placeholder='1000' name='oneThousand' value={demonAmount.oneThousand}/>
                      </div>
                      <div className="demoin_size">
                      <span>2000</span><input type="number" className='demon' id='twothousand' onChange={(e)=>serDemonAmount({...demonAmount,twoThousand:e.target.value})} placeholder='2000' name='twoThousand' value={demonAmount.twoThousand}/>
                      </div>
                      </div>
                      </div>
                      
                        
                    </div>
                    <div className="amount">
                      <button className='setzero' onClick={setZero}>
                      <ReplayIcon/>
                      </button>
                      <input
                        type="number"
                        autoComplete='off'
                        onChange={numToWord}
                        id='amount'
                        value={amount}
                        name='amount'
                        placeholder='amount'
                        size={30}
                      />
                    </div>
                    <div className='amountlet'>
                      <input
                        type="text"
                        disabled
                        value={amountlet}
                        id='amountlet'
                        name='amountlet'
                        size={40}
                      />
                    </div>
                  </div>
                  <div className="input_right">
                    <h2>Number</h2>
                    <div className="number">
                      <label htmlFor="number">Number</label>
                      <input type='text' placeholder='number' name='number' value={inumber} onChange={(e)=>{setInumber(e.target.value)}}/>
                      <button onClick={getNumberData}><TroubleshootIcon/></button> 
                    </div>
                    <h2>Area tag</h2>
                    <div className="area">
                      <label htmlFor="area">Area</label>
                      <ReactTransliterate 
                        value={userEditData.area}
                        onKeyDown={addiruppu}
                        onChangeText={(text) => setUserEditData(prevData => ({ ...prevData, area: text }))}
                        lang='ta'
                        name='area'
                        placeholder='Enter Area'
                        autoComplete='off'
                        size={60}
                      />
                    </div>
                    <div className='arealist'>
                        {area && area.area.filter(list=>list.area.includes(userEditData.area.trim())).map((list)=>
                          <button onClick={(e)=>{e.preventDefault();setUserEditData((prevData)=>({...prevData,area:list.area}))}} >{list.area}</button>  
                        )}

                    </div>

                    <div className='arealist'>
                        {place && place.area[0].place.filter(alist=>alist.includes(userEditData.area.trim())).map((alist,index)=>
                          <button onClick={(e)=>{e.preventDefault();setUserEditData((prevData)=>({...prevData,area:alist}))}} > {alist}</button>  
                        )}

                    </div>

                  </div>
                </div>
                {stopmoi ?<></>:<button  className='submitbtn'>Submit to Register</button>}
                 
                
              </div>
            </form>
          </div>
                          {/* buttom start */}
          <div>
            <div className="bottom">
              
              <div>
                {siguser && <Totalamount siguser={siguser}/>}
              </div>
              <div>
                <h2>Operator</h2>
                {
                  cookies.get('operator') === 'admin'?
                  <div>
                    {siguser && <Adminoperator siguser={siguser}/>}
                  </div>:
                  <div>
                    {siguser && <Singleoperator  siguser={siguser} name={cookies.get('operator')}/>}
                  </div>
                }
                
              </div>
                  
            </div>
          </div>
        </div>
                            {/* buttom end */}
        <div className="right">
          <h3 style={{display:'inline'}}>Moi List</h3> 
          {permission ? (
      <button onClick={onpermission} style={{ background: 'none', border: 'none' }}>
        <LockIcon />
      </button>
    ) : (
      <button onClick={offpermission} style={{ background: 'none', border: 'none' }}>
        <LockOpenIcon />
      </button>
    )}
            
          <div style={{paddingTop:'10px'}}className="business">
                      <ReactTransliterate
                        value={search}
                        onChangeText={(text) => setSearch(text)}
                        lang='ta'
                        name='searchcus'
                        className='searchcus'
                        placeholder='search here'
                        autoComplete='off'
                      />
                    </div>
          
          <div className='rightcon'>
            <ul>
              <li>
                {siguser && (
                  <div>
                    {siguser.getCus.moiList.filter((list)=>list.area.includes(search.trim()) | list.name.includes(search.trim())).slice().reverse().map((list, index) => (
                      <>
                      
                        {cookies.get('operator') === list.operator && cookies.get('operator') !== 'admin' ?
                        <div key={list._id} className="detail">
                         <div>
                         <div>
                           <h4>{siguser.getCus.moiList.length - index}</h4>
                           <h4>{list.name}</h4>
                           <p>{list.area}</p>
                         </div>
                         <div className='amount'>
                           <h3>{list.amount}</h3>
                           <h6>{list.amountlet}</h6>
                         </div>
                         <div className="buttons">
                           <Link to='/printing' state={{ siguser, admin, index: siguser.getCus.moiList.length - index, list }}>
                             Print
                           </Link>
                           <button className='editbtn' onClick={() => onEdit(list._id, list.name, list.business, list.area, list.amount, list.amountlet,list.number)}>
                             Edit
                           </button>
                           <button className='delbtn' onClick={() => onDelete(list._id)}>Del</button>
                         </div>
                       </div>
                       </div>:<></>
                      }
                      
                      
                      {cookies.get('operator') == 'admin'?
                      <div key={list._id} className="detail">
                         <div>
                         <div>
                           <h4>{siguser.getCus.moiList.length - index}</h4>
                           <h4>{list.name}</h4>
                           <p>{list.area}</p>
                         </div> 
                         <div className='amount'>
                           <h3>{list.amount}</h3>
                           <h6>{list.amountlet}</h6>
                         </div>
                         <div className="buttons">
                           <Link to='/printing' state={{ siguser, admin, index: siguser.getCus.moiList.length - index, list }}>
                             Print
                           </Link>
                           
                           {permission?<></>:<><button className='editbtn' onClick={() => onEdit(list._id, list.name, list.business, list.area, list.amount, list.amountlet,list.number)}>
                             Edit
                           </button>
                           <button className='delbtn' onClick={() => onDelete(list._id)}>Del</button></>}
                           
                         </div>
                       </div>
                       </div>:<></>

                      }
                      
                      </>
                    ))}
                  </div>
                )}
              </li>
            </ul>
          </div>
          {siguser && (
            <div>
                <Link to='/pdf_generator' state={{ id: siguser.getCus._id}}>
                  <button className='generatepdf'>Generate PDF</button>
                </Link>
              <Link to='/pdf_generatorsub' state={{ id: siguser.getCus._id }}>
              <button className='generatepdf'>Generate PDF</button>
            </Link>
            </div>
            
          )}
        </div>
      </div>
    </div>
}
</Fragment>
  );
};

export default Edit;
