  import React, { useEffect ,useRef} from 'react'
import './style.css'
import {Link ,Outlet, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutAdmin } from '../../redux/actions/adminAction'

const Main = () => {


  const dispatch = useDispatch()
  const navigation = useNavigate()
  const logout = () => {
    dispatch(logoutAdmin)
  }
  return (
    <div className='main'>
      <div className='mainCon'>
        {/* nav bar start */}
        <div className="navbar">
          <div className="left">
              <h2>Suruthi MoiNote</h2>
              <ul>
                <li><Link to='/main/'>Home</Link></li>
                <li><Link to='/main/userdata/'>Userdata</Link></li>
              </ul>
          </div>
          <div className="right">
  
            <button onClick={logout} className='logoutbtn'>LogOut</button>
          </div>
        </div>
      </div>
      <Outlet/>
    </div>
  )
}

export default Main