import { createSlice } from "@reduxjs/toolkit";


const TotalUserSlice = createSlice({
    name:'TotalUser',
    initialState:{
        loading:false
    },
    reducers:{
        settotaluserReq(state,action){
            return{
                ...state,
                loading:true
            }
        },
        settotaluserRes(state,action){
            return{
                ...state,
                loading:false,
                alltotaluser:action.payload
            }
        },
        edittotaluserReq(state,action){
            return{
                ...state,
                loading:true
            }
        },
        edittotaluserRes(state,action){
            return{
                ...state,
                loading:false,
                alltotaluser:action.payload
            }
        },
        gettotaluserReq(state,action){
            return{
                ...state,
                loading:true
            }
        },
        setuserFailed(state,action){
            return{
                loading: false,
                error:action.payload
            }
        },
        clearError(state,action){
            return{
                
            }
        },
        cleardata(state,action){
            return{
                
            }
        },
        gettotaluserRes(state,action){
            return{
                ...state,
                loading:false,
                totaluser:action.payload
            }
        },
        uptotaluserReq(state,action){
            return{
                ...state,
                loading:true
            }
        },
        uptotaluserRes(state,action){
            return{
                ...state,
                loading:false,
                totaluser:action.payload
            }
        },
        getAlltotaldata(state,action){
            return{
                ...state,
                loading:false,
                alltotaluser:action.payload
            }
        },
        delAlltotaldataReq(state,action){
            return{
                ...state,
                loading:true
            }
        },
        delAlltotaldataRes(state,action){
            return{
                ...state,
                loading:false,
                alltotaluser:action.payload
            }
        }
    }
})

const {actions,reducer} = TotalUserSlice
export const {settotaluserReq,settotaluserRes,gettotaluserReq,gettotaluserRes,uptotaluserReq,uptotaluserRes,setuserFailed,clearError,cleardata,getAlltotaldata,delAlltotaldataReq,delAlltotaldataRes,edittotaluserReq,edittotaluserRes} = actions
export default reducer