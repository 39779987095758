import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import img1 from '../../asset/image/bannerfirst/1.png'
import img2 from '../../asset/image/bannerfirst/2.webp'
import img3 from '../../asset/image/bannerfirst/3.webp'
import './bannerfirst.css'
const BannerFrist = () => {
  return (
    <div style={{width:'80%',margin:'30px auto',textAlign:'center'}}>
        <h2>சுருதி மொய்நோட் முக்கிய நோக்கம்</h2>
        <p style={{marginTop:'10px',color:'gray',fontSize:'1rem'}}>மொய் செய்வோம்!!! மொய் பெறுவோம்!!!
            நமது கலாச்சாரத்தை காப்போம் நவீன முறையில்.
            நமது தொன்று தொட்ட கலாச்சாரத்தை நவீனயுகத்திற்கு ஏற்ப
            புதிய பரிமானத்தை நோக்கி நமது சுருதி மொய்நோட்</p>
        <div className="cards">
                    <Card sx={{ minWidth : 250,maxWidth: 250, marginTop:'20px'}}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="140"
                    image={img1}
                    alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="p" component="div">
                        மொய் @ கம்ப்யூட்டர் 
                    </Typography>
                    </CardContent>
                </CardActionArea>
                </Card>
                <Card sx={{ minWidth : 250,maxWidth: 250, marginTop:'20px' }}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="140"
                    image={img2}
                    alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="p" component="div">
                        பழைய மொய் நோட்டுகளை புதுப்பித்தல் 
                    </Typography>
                    </CardContent>
                </CardActionArea>
                </Card>
                <Card sx={{ minWidth : 250,maxWidth: 250, marginTop:'20px' }}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="140"
                    image={img3}
                    alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="p" component="div">
                        மொய் கைபேசியல் அடக்கம் 
                    </Typography>
                    </CardContent>
                </CardActionArea>
                </Card>
        </div>
        <div className="futureList" style={{marginTop:'40px'}}>
            <h4>
                சுருதி மொய் நோட்  வழங்கும் சிறப்பு வசதிகள் 
            </h4>
            <div className="list">
                
            </div>
        </div>
    </div>
  )
}

export default BannerFrist