import { createSlice } from "@reduxjs/toolkit";

const admin = createSlice({
    name:"admins",
    initialState:{
        loading:false
    },
    reducers:{
        admingetRequest(state,action){
            return{
                ...state,
                loading: true
            }
        },
        admingetSuccess(state,action){
            return{
                ...state,
                isadministrator:true,
                loading: false,
                admin:action.payload
            }
            
        },
        adminfailed(state,action){
            return{
                ...state,
                loading: false,
                error:action.payload
            }
        },
        clearError(state,action){
            return{
                ...state,
                error:null
            }
        },
        loadadminRequest(state,action){
            return{
                ...state,
                loading: true
            }
        },
        loadadminSuccess(state,action){
            return{
                ...state,
                isadministrator:true,
                loading: false,
                admin:action.payload
            }
            
        },
        logoutadminRequest(state,action){
            return{
                ...state,
                loading: true
            }
        },
        logoutadminSuccess(state,action){
            return{
                isadministrator:false
            }
            
        }
    }
})

const {actions , reducer} = admin
export const {admingetRequest,admingetSuccess,clearError,adminfailed,loadadminSuccess,loadadminRequest,logoutadminRequest,logoutadminSuccess} = actions
export default reducer