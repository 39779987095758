import { createSlice } from "@reduxjs/toolkit";

const area = createSlice({
    name:"areas",
    initialState:{
        loading:false
    },
    reducers:{
        requestArea(state,action){
            return{
                loading:true
            }
        },
        successRequestArea(state,action){
            return{
                loading:false,
                place:action.payload
            }
        }
    }
})


const {actions , reducer} = area
export const {requestArea,successRequestArea} = actions
export default reducer