import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { useEffect } from 'react'

const Authroute = ({children}) => {
    const navigate  = useNavigate()    
    const {isadministrator} = useSelector((state)=>state.adminstate)
    if(isadministrator == false){
        return navigate('/admin')
    }
    return(children)
}

export default Authroute