import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
    name:'users',
    initialState:{
        loading:false
    },
    reducers:{
        userRequest(state,action){
            return{
                ...state,
                loading: true
            }
        },
        userSuccess(state,action){
            return{
                ...state,
                loading: false,
                user:action.payload
            }
        },
        userLogin(state,action){
            return{
                loading: true
            }
        },
        userLoginSuccess(state,action){
            return{
                loading: false,
                user:action.payload,
                isadministrator:true,
            }
        },
        userLoginFailed(state,action){
            return{
                loading: false,
                error:action.payload
            }
        },
        clearError(state,action){
            return{
                ...state,
                error:null
            }
        },
        userSingleRequest(state,action){
            return{
                ...state,
                loading: true
            }
        },
        userSingleSuccess(state,action){
            return{
                ...state,
                loading: false,
                siguser:action.payload
            }
        },
        pdfgenRequest(state,action){
            return{
                ...state,
                loading: true
            }
        },
        pdfgenSuccess(state,action){
            return{
                ...state,
                loading: false,
                area:action.payload
            }
        },
        createuserReq(state,action){
            return{
                loading:true
            }
        },
        createuserSuccess(state,action){
            return{
                loading:false,
                user:action.payload
            }
        },
        updatemoiReq(state,action){
            return{
                ...state,
                loading:true,
                stopmoi:true
            }
        },
        updatemoiSuccess(state,action){
            return{
                ...state,
                stopmoi:false,
                loading:false,
                siguser:action.payload
            }
        },
        indelReq(state,action){
            return{
                loading:true
            }
        },
        indelSuccess(state,action){
            return{
                ...state,
                loading:false,
                siguser:action.payload
            }
        },
        inupdateReq(state,action){
            return{
                ...state,
                loading:true,
                stopmoi:true
            }
        },
        inupdateSuccess(state,action){
            return{
                ...state,
                loading:false,
                stopmoi:false,
                siguser:action.payload
            }
        },
        adupdatemoiReq(state,action){
            return{
                loading:true
            }
        },
        adupdatemoiSuccess(state,action){
            return{
                loading:false,
                user:action.payload
            }
        },
        changeperReq(state,action){
            return{
                loading:true
            }
        },
        changeperSuccess(state,action){
            return{
                ...state,
                loading:false,
                siguser:action.payload
            }
        },
        delCus(state,action){
            return{
                loading:false
            }
        },
        loaduserRequest(state,action){
            return{
                loading: true
            }
        },
        loaduserSuccess(state,action){
            return{
                isadministrator:true,
                loading: false,
                user:action.payload
            }
            
        },
        logoutuserRequest(state,action){
            return{
                loading: true
            }
        },
        logoutuserSuccess(state,action){
            return{
                isadministrator:false
            }
            
        },
        clrUser(state,action){
            return{
                ...state,
                siguser:null
            }
        }
    }
})

const { actions , reducer}  = user
export const {userRequest,userSuccess,userSingleSuccess,userLogin,userLoginSuccess,userLoginFailed,clearError,userSingleRequest,createuserReq,createuserSuccess,updatemoiReq,updatemoiSuccess,indelReq,indelSuccess,inupdateReq,inupdateSuccess,adupdatemoiReq,adupdatemoiSuccess,delCus,loaduserSuccess,loaduserRequest,logoutuserRequest,logoutuserSuccess,pdfgenRequest,pdfgenSuccess,clrUser,changeperReq,changeperSuccess} = actions
export default reducer