import axios from 'axios'
import {settotaluserReq,settotaluserRes,gettotaluserReq,gettotaluserRes,uptotaluserReq,uptotaluserRes,setuserFailed,clearError,cleardata,getAlltotaldata, delAlltotaldataReq, delAlltotaldataRes, edittotaluserReq, edittotaluserRes} from "../slice/totalUserSlice"

export const setTotalUser = (userdata) => async(dispatch)=>{
    try{
        console.log(userdata)
        dispatch(settotaluserReq())
        const {data} = await axios.post('/api/v1/setuser',userdata,{
            headers: {
                'Content-Type': 'application/json'
              } 
        }
        )
        console.log(data)
        dispatch(settotaluserRes(data))
    }
    catch(e){
        dispatch(setuserFailed(e.response.data.message))
    }
}

export const editTotalUserset = (id,userdata) => async(dispatch)=>{
    try{
        console.log('hi')
        dispatch(edittotaluserReq())
        const {data} = await axios.put(`/api/v1/upuser/${id}`,userdata,{
            headers:{
                'Content-Type':'application/json'
            }
        })
        dispatch(edittotaluserRes(data))
    }
    catch(e){
        console.log(e)
    }
}


export const getTotalUser = (id) =>async(dispatch)=>{
    try{
        dispatch(gettotaluserReq())
        const {data} = await axios.get(`/api/v1/getuser/${id}`)
        dispatch(gettotaluserRes(data))
    }
    catch(e){
        console.log(e)
    }
}
export const errorclr = dispatch =>{
    dispatch(clearError())
}
export const clearTotaldata = dispatch =>{
    dispatch(cleardata())
}

export const getAllTotaluser = async(dispatch) =>{
    try{
        console.log('hi')
        dispatch(gettotaluserReq())
        const {data} = await axios.get('/api/v1/getalluser')
        dispatch(getAlltotaldata(data))
    }
    catch(e){
        console.log(e)
    }
}

export const  delTotaluserAction = (id) =>async(dispatch)=>{
    try{
        dispatch(delAlltotaldataReq())
        console.log('del Total')
        const {del} = await axios.delete(`/api/v1/deluser/${id}`)
        const {data} = await axios.get('/api/v1/getalluser')
        dispatch(delAlltotaldataRes(data))
    }
    catch(e){
        console.log(e)
    }
}