import React, { useEffect, useState } from 'react'

export const Totalamount = (props) => {
    const {siguser} = props
    const [total,setTotal] =useState()
    useEffect(()=>{
        let subtotal=0
        siguser.getCus.moiList.map(list=>{
            subtotal = subtotal + list.amount
        })
        setTotal(subtotal)
    },[siguser])
    
  return (
    <div>
        <h4>Totalamount</h4>
        <h1>{total}</h1>
    </div>
  )
}