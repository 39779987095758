import {configureStore ,combineReducers} from "@reduxjs/toolkit"
import userReducer from './slice/userslice'
import adminReducer from './slice/adminslice'
import areaReducer from './slice/areaslice'
import totaluserReducer from "./slice/totalUserSlice"
const reducer = combineReducers({
    userstate:userReducer,
    adminstate:adminReducer,
    areastate:areaReducer,
    totalUserstate:totaluserReducer
})

const store = configureStore({
    reducer
})

export default store