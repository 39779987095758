import React, { useEffect, useState } from 'react'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { getAdmin , errorclr} from '../../redux/actions/adminAction'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const Login = () => {

    let {loading ,isadministrator, admin,error} = useSelector((state)=>state.adminstate)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [adminData,setadminData] = useState({
        email:'',
        password:''
    })

    const onchange =(e) =>{
        setadminData({...adminData,[e.target.name]:e.target.value})
    }
    const onSubmitHandler = (e) =>{
        e.preventDefault()
        const formdata = new FormData()
        console.log(adminData)
        formdata.append("email",adminData.email)
        formdata.append("password",adminData.password)
        dispatch(getAdmin(formdata))
    }
    useEffect(()=>{
        if(isadministrator == true || admin){
            navigate('/main')
        }
        if(error){
            toast(error , {
                position:toast.POSITION.BOTTOM_CENTER,
                type:'error',
                onOpen:()=> {dispatch(errorclr)}
            })
            return
        }
    },[isadministrator,admin,error])

  return (
    <div className="viewchange">
        <div className="viewConchange">
            <div className="topchange">
                <h2>Suruthi MoiNote</h2>
                <h5>Admin Login</h5>
            </div>
            <div className="buttomchange">
                <form onSubmit={onSubmitHandler}>
                    <div className="getinput">
                        <div className="usernamechange">
                            <label htmlFor="">username</label>
                            <input type="text" name='email' onChange={onchange} className='username' placeholder='username'/>
                        </div>
                        <div className="passwordchange">
                            <label htmlFor="">password</label>
                            <input type="password" name='password' onChange={onchange} className='password' placeholder='password'/>
                        </div>
                        <button type='submit'>Login</button>
                        <p> Any problem connect to Team members</p>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Login