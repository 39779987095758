import {useState,useEffect} from 'react'
import SimpleImageSlider from "react-simple-image-slider";
import image1 from '../../asset/image/slide1.jpg'
import image2 from '../../asset/image/slide7.jpg'
import image3 from '../../asset/image/slide3.jpg'
import image4 from '../../asset/image/slide4.jpg'
import image5 from '../../asset/image/slide5.jpg'
import image6 from '../../asset/image/slide6.jpg'
import './imageSilder.css'


const images = [
  { url: image1 },
  { url: image2 },
  { url: image3 },
  { url: image4 },
  { url: image5 },
  { url: image6 }
];

const YourComponent = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
  
    return (
      <div>
        {windowWidth > 1000 ? (
          <div className='sliderDiv' style={{ display: 'flex', height: '60vh', background: 'none' }}>
            <SimpleImageSlider
              width={'50%'}
              height={'60%'}
              images={images}
              showNavs={true}
              autoPlay={true}
              autoPlayDelay={2}
            />
          </div>
        ) : (
          <div className='sliderDiv' style={{ display: 'flex', height: '40vh', background: 'none' ,marginTop:'20px'}}>
            <SimpleImageSlider
              width={'80%'}
              height={'40%'}
              images={images}
              showNavs={true}
              autoPlay={true}
              autoPlayDelay={2}
            />
          </div>
        )}
      </div>
    );
  };
  
  export default YourComponent;