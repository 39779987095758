import logo from '../../asset/image/logo.png'
import vanakam from '../../asset/image/vanakam.png'
import React ,{useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import './printing.css'

const Printing = () => {

    const navigate = useNavigate

  const {state} = useLocation();
  const [date,setdate] =useState()
  const [month,setmonth] =useState()
  const [year,setyear] =useState()
  const [hour,sethour] =useState()
  const [min,setmin] =useState()
  const [sec,setsec] =useState()
  const [finindex , setindex] = useState()

  const {admin , index , siguser , list} = state
  useEffect(()=>{
    let d = new Date(list.date)
    setdate(d.getDate())
    setmonth(d.getMonth())
    setyear(d.getFullYear())
    sethour(d.getHours())
    setmin(d.getMinutes())
    setsec(d.getSeconds())
    setindex((index))
    console.log(admin)
    setTimeout(() => {
        window.print()
        window.print()
        let url = '/main/edit/'+ siguser.getCus._id
        window.location.href = url;
      }, 100);
  },[])

  


  return (
    <div className="print_container">
                                                <div className={index}>
                                                <div className="print_content">
                                                <div className="heade">
                                                    <div className="logo">
                                                        <img src={logo} alt="" width='50px' />
                                                    </div>
                                                    {admin &&
                                                    <div className='brandname'>
                                                        <h3>{admin.admin.location}</h3>
                                                        <h2>சுருதி மொய்நோட் </h2>
                                                        <h3>வாழ்க வளமுடன்...</h3>
                                                    </div>}
                                                </div>
                                                <div className="cutDetail">
                                                    <h4>{siguser.getCus.username}</h4>
                                                    <h4>{siguser.getCus.subusername}</h4>
                                                    <h5>இல்ல {siguser.getCus.funtype}</h5>
                                                    <h5>{siguser.getCus.funside}</h5>
                                                    <h5>{siguser.getCus.address}</h5>
                                                    <h5>{siguser.getCus.number}</h5>
                                                </div>
                                                <div className='moist'>
                                                    <div>
                                                        <label htmlFor="\">வ.எண்</label>
                                                        <h3>: {finindex}</h3>
                                                        
                                                    </div>
                                                    <div>
                                                        <label htmlFor="\">பெயர்</label>
                                                        <div>
                                                            <h3>: {list.name}</h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="\">தேதி</label>
                                                        <div>
                                                            <h4>: {28}-{month+1}-{year}</h4>
                                                        </div>
                                                        
                                                    </div>
                                                    <div>
                                                        <label htmlFor="\">தொழில்</label>
                                                        <div>
                                                            <h4>: {list.business}</h4>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="\">ஊர்</label>
                                                        <div>
                                                            <h4>: {list.area}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='operatorname'>
                                                    <h5>எழுத்தர்    :  {list.operator}</h5>
                                                </div>
                                                <div className="amountDetail">
                                                    <div className="to_amount">
                                                        <h5>பங்களிப்பு தொகை</h5>
                                                        {list.demoAmount ? <table>
                                                            <tr>
                                                                <th colSpan={100}>ரூபாய் நோட்  விபரம்</th>
                                                            </tr>
                                                                {list.demoAmount.twoThousand !== 0 ?
                                                                <tr>
                                                                <td>2000</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.twoThousand}</td>
                                                                <td>{list.demoAmount.twoThousand * 2000}</td>
                                                            </tr>:<></>}
                                                                {list.demoAmount.oneThousand !== 0 ?
                                                                <tr>
                                                                <td>1000</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.oneThousand}</td>
                                                                <td>{list.demoAmount.oneThousand * 1000}</td>
                                                                </tr>:<></>}
                                                                {list.demoAmount.fiveHundrend !== 0 ?
                                                                <tr>
                                                                <td>500</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.fiveHundrend}</td>
                                                                <td>{list.demoAmount.fiveHundrend * 500}</td>
                                                                </tr>:<></>}
                                                                {list.demoAmount.twoHundrend !== 0 ?
                                                                <tr>
                                                                <td>200</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.twoHundrend}</td>
                                                                <td>{list.demoAmount.twoHundrend * 200}</td>
                                                                </tr>:<></>}
                                                                {list.demoAmount.oneHundrend !== 0 ?
                                                                <tr>
                                                                <td>100</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.oneHundrend}</td>
                                                                <td>{list.demoAmount.oneHundrend * 100}</td>
                                                                </tr>:<></>}
                                                                {list.demoAmount.fifty !== 0 ?
                                                                <tr>
                                                                <td>50</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.fifty}</td>
                                                                <td>{list.demoAmount.fifty * 50}</td>
                                                                </tr>:<></>}
                                                                {list.demoAmount.twenty !== 0 ?
                                                                <tr>
                                                                <td>20</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.twenty}</td>
                                                                <td>{list.demoAmount.twenty * 20}</td>
                                                                </tr>:<></>}
                                                                {list.demoAmount.ten !== 0 ?
                                                                <tr>
                                                                <td>10</td>
                                                                <td>x</td>
                                                                <td>{list.demoAmount.ten}</td>
                                                                <td>{list.demoAmount.ten * 10}</td>
                                                                </tr>:<></>}
                                                        </table> : <></>}
                                                        
                                                        <h1>ரூ. {list.amount}</h1>
                                                        <h5 className='amountword'>{list.amountlet}</h5>
                                                        <h5>தங்கள் வருகைக்கு நன்றி...</h5>
                                                    </div>
                                                </div>
                                                {admin && 
                                                <div className="footer">
                                                    <h3>Suruthi moinote</h3>
                                                    <h6>{admin.admin.email}</h6>
                                                    <h6>{admin.admin.number} , {admin.admin.subnumber}</h6>
                                                </div>
                                                }
                                                </div>
                                            </div>
    </div>
  )
}

export default Printing
